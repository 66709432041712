import axios from "axios";

export class LoginApi {
  static status;
  static id;

  static async logIn({ email, password }) {
    await axios
      .post("https://forone.2ng.ru/auth/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        this.id = response.data.session_id;
        this.status = response.status;
      })
      .catch((error) => {
        this.data = error.response.data.detail;
        this.status = error.response.status;
      });

    return { status: this.status, id: this.id };
  }
}
