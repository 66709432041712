import { TeamAccountApi } from "../Api/TeamAccountApi";
import { useEffect, useState } from "react";
import headPuzzleRight from "../Assets/Images/white_empty_puzzle_right.png";
import headPuzzleLeft from "../Assets/Images/white_empty_puzzle_left.png";
import emptyMember from "../Assets/Images/empty_member.png";
import emptyTeam from "../Assets/Images/empty_team.png";
import MemberPopUp from "../Components/Modules/PopUp/MemberPopUp";
import { DesignedInput } from "../Components/UI/DesignedInput";
import { PDFApi } from "../Api/PDFApi";
import agreement from "../Assets/Docs/Согласие.pdf";

export const TeamAccountPage = () => {
  const [teamInfo, setTeamInfo] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberInfo, setMemberInfo] = useState({});
  const [openMember, setOpenMember] = useState(false);
  const [fileAccess, setFileAccess] = useState(false);

  function closePop(status) {
    setOpenMember(status);
  }

  useEffect(() => {
    (async () => {
      const teamFetch = await TeamAccountApi.getTeam();
      const memberFetch = await TeamAccountApi.getMemberList();

      setTeamInfo(teamFetch[teamFetch.length - 1]);
      setMemberList(memberFetch);
    })();
  }, []);

  async function onAgreement(e) {
    const agreementFetch = await PDFApi.sendFile(teamInfo.id, e.files[0]);

    if (agreementFetch.status === 200) {
      localStorage.setItem("agreementAccess", JSON.stringify(true));
      setFileAccess(true);
    } else if (agreementFetch.data === "Only PDF files are allowed") {
      alert("Разрешено загружать только файлы в формате PDF");
    }
  }

  function onMemberOpen(info) {
    setMemberInfo(info);
    setOpenMember(true);
  }

  return (
    <div className="registrationPage">
      <div className="teamHead">
        <img alt="" src={headPuzzleLeft} />
        Личный кабинет команды
        <img alt="" src={headPuzzleRight} />
      </div>
      <div className="teamCard">
        <div className="teamIcon">
          {teamInfo.photo ? (
            <img
              src={`https://forone.2ng.ru/${teamInfo.photo}`}
              alt="Фото команды"
            ></img>
          ) : (
            <img
              src={emptyTeam}
              alt="Фото команды"
              className="emptyTeamPhoto"
            ></img>
          )}
        </div>
        <div className="teamCardContent">
          <div className="teamName">{teamInfo.name}</div>
          <div className="teamURL">
            <a href="">АККАУНТ КОЛЛЕКТИВА</a>
          </div>
        </div>
      </div>

      {memberList.length > 1 && (
        <div className="downloadApprove">
          <div
            onClick={() => {
              window.open(agreement);
            }}
          >
            СКАЧАТЬ СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </div>
          <label>
            Для того, чтобы ваша заявка прошла регистрацию, подпишите согласие о
            персональных данных и загрузите его в соответствующую форму!
          </label>{" "}
          {!fileAccess ? (
            <DesignedInput
              type="file"
              multiple="multiple"
              onChange={onAgreement}
            />
          ) : (
            <DesignedInput
              type={"text"}
              placeholder={"ФАЙЛЫ ЗАГРУЖЕНЫ"}
              disabled={true}
              // disabled={!accessApplication}
            ></DesignedInput>
          )}
        </div>
      )}

      <div className="downloadCertificat">
        <div>
          ПО ОКОНЧАНИИ КОНКУРСА ЗДЕСЬ ПОЯВИТСЯ ССЫЛКА ДЛЯ СКАЧИВАНИЯ СЕРТИФИКАТА
        </div>
      </div>

      <div className="downloadPosition">
        <div
          onClick={() => {
            window.open(
              "https://drive.google.com/file/d/1yls-OZfj9WSYpAJwCClymB4KNsc5KMrc/view"
            );
          }}
        >
          СКАЧАТЬ ПОЛОЖЕНИЕ О КОНКУРСЕ - ФЕСТИВАЛЕ
        </div>
      </div>

      <div className="teamList">
        {memberList.map((person, index) => {
          return (
            <div
              key={index}
              className="teamParticipant"
              onClick={() => onMemberOpen(person)}
            >
              {person.photo ? (
                <img
                  className="teamParticipantPhoto"
                  src={`https://forone.2ng.ru/${person.photo}`}
                  alt="Фото участника"
                />
              ) : (
                <img
                  className="teamParticipantPhoto emptyMemberPhoto"
                  src={emptyMember}
                  alt="Фото участника"
                ></img>
              )}

              <div className="teamParticipantName">
                <div>{person.last_name}</div> <div>{person.first_name}</div>
              </div>
            </div>
          );
        })}
      </div>
      {openMember && (
        <MemberPopUp memberInfo={memberInfo} close={(e) => closePop(e)} />
      )}
    </div>
  );
};
