import { useEffect, useState } from "react";
import { AdminApi } from "../Api/AdminApi";
import { Table } from "../Components/Modules/Table/Table";
import { useNavigate } from "react-router-dom";
import { DesignedButton } from "../Components/UI/DesignedButton";

export const AdminAccountPage = () => {
  const [ratings, setRatings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const fetchRatings = await AdminApi.getRatings();

      if (fetchRatings.status === 200) {
        setRatings(fetchRatings.ratings);
      } else {
        navigate("/login_admin");
      }
    })();
  }, []);

  return (
    <div className="registrationPage">
      <Table ratings={ratings} />
      <div className="backToScore">
        <DesignedButton
          text={"Оценить новую команду"}
          callback={() => {
            window.scrollTo(0, 0);
            navigate("/score");
          }}
        />
      </div>
    </div>
  );
};
