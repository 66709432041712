import { useState } from "react";
import { DesignedButton } from "../Components/UI/DesignedButton";
import { DesignedInput } from "../Components/UI/DesignedInput";
import { useNavigate } from "react-router-dom";
import { RecoverApi } from "../Api/RecoverApi";
import ReactLoading from "react-loading";

export const RecoverPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);

  function onEmailChange(e) {
    setEmail(e);
  }

  async function fetch() {
    const loginFetch = await RecoverApi.recover({ email });

    setLoad(true);
    if (loginFetch.status === 200) {
      alert("На вашу электронную почту отправлен новый пароль");
      navigate("/");
    } else if (loginFetch.status !== 200) {
      if (loginFetch.data === "Password recovery is not allowed at this time") {
        alert("Восстановление пароля в настоящее время невозможно.");
        return;
      } else if (loginFetch.data === "An email address must have an @-sign.") {
        alert("Почта введена неверно");
      } else if (loginFetch.data === "User with this email does not exist") {
        alert("Пользователь с таким адресом электронной почты не существует");
      } else if (
        loginFetch.data ===
        "Password recovery is only allowed once every 24 hours"
      ) {
        alert("Восстановление пароля доступно раз в 24 часа");
      } else {
        alert("Возникла ошибка");
        return;
      }
      setLoad(false);
    }
    setLoad(false);
  }

  function onLogin(e) {
    e.preventDefault();
    fetch();
  }

  return (
    <div className="registrationPage">
      <form className="registrationForm">
        <DesignedInput
          type={"email"}
          placeholder={"Введите почту"}
          onChange={onEmailChange}
          required={true}
          className="inputRegistration"
        />
        {load ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <DesignedButton text={"Восстановить пароль"} callback={onLogin} />
        )}
        <div className="switchSign" onClick={() => navigate("/")}>
          Войти
        </div>{" "}
        <div className="switchSign" onClick={() => navigate("/registration")}>
          Зарегистрироваться
        </div>
      </form>
    </div>
  );
};
