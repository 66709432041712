import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminApi } from "../../../Api/AdminApi";
import TimeDisplay from "../../UI/AdminTable/TimeDisplay";
import ReactLoading from "react-loading";

export const Table = () => {
  const navigate = useNavigate();
  const [ratingList, setRatingList] = useState([]);
  const [checkDateList, setCheckDateList] = useState([]);
  const [load, setLoad] = useState(false);

  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    (async () => {
      const fetchRatings = await AdminApi.getRatings();

      if (fetchRatings.status === 200) {
        setRatings(fetchRatings.ratings);
      } else {
        navigate("/login_admin");
      }
    })();
  }, []);

  async function getTeamRating() {
    setLoad(true);

    let rawList = await Promise.all(
      ratings.map(async (item) => {
        let score =
          item.artistry +
          item.costume_matching +
          item.creativity +
          item.inclusivity +
          item.mastery_of_combination +
          item.performance_skill;

        let data = await AdminApi.getTeamRating(item.team_id);

        let checkDisability =
          data.data.members?.some(
            (member) => member.disability_group !== undefined
          ) || false;

        return {
          ...data.data,
          score: score,
          disabilty: checkDisability,
          date: item.rating_date,
        };
      })
    );

    setRatingList(rawList);
    setLoad(false);
  }

  async function getDateCheck(e) {
    setCheckDateList((prev) => [...prev, e]);
  }

  useEffect(() => {
    (async () => {
      await getTeamRating();
    })();
  }, [ratings]);

  useEffect(() => {
    let rawList = ratingList.map((item) => {
      let dateData;
      checkDateList.map((date) => {
        if (item.team_id === date.team_id) {
          dateData = date;
        }
      });
      return { ...item, check_date: dateData?.check_date };
    });

    setRatingList(rawList);
  }, [checkDateList]);

  async function onTeamClick(team) {
    localStorage.setItem("scored_team", JSON.stringify(team));
    navigate("/scored_team");
  }

  return (
    <div className="designedTable">
      <div className="tableHead">
        <div>Название</div>
        <div>Количество участников</div>
        <div>Наличие инвалидности</div>
        <div>Ссылка на видео </div>
        <div>Баллы</div>
        <div>Таймер</div>
      </div>

      {load ? (
        <ReactLoading
          type="spinningBubbles"
          color="black"
          height={"50px"}
          width={"50px"}
        />
      ) : (
        <div className="tableBody">
          {ratingList.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  item.check_date ? "tableRow" : "tableRow tableRowDisabled"
                }
                onClick={item.check_date ? () => onTeamClick(item) : null}
              >
                <div
                  className={
                    item.check_date
                      ? "boldRow tableTeamName"
                      : "boldRow tableTeamName tableRowDisabled"
                  }
                >
                  {item.name}
                </div>
                <div>{item.members.length}</div>
                {item.disabilty === true ? (
                  <div>
                    <input
                      className="customCheckboxInput"
                      type="checkbox"
                      checked
                      disabled
                    />
                    <div className="customCheckbox"></div>
                  </div>
                ) : (
                  <div>{item.disabilty}</div>
                )}

                <a
                  href={item.check_date ? item.video : ""}
                  className={item.check_date ? "" : "tableRowDisabled"}
                >
                  Нажмите
                </a>
                <div>{item.score}</div>
                <TimeDisplay
                  teamId={item.team_id}
                  date={item.date}
                  checkDate={getDateCheck}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
