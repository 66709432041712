import axios from "axios";

export class PDFApi {
  static status;
  static data;

  static async sendFile(teamId, file) {
    const formData = new FormData();
    formData.append("pdf_file", file);

    await axios
      .post(
        `https://forone.2ng.ru/teams/teams/${teamId}/upload_pdf`,
        formData,
        {
          params: {
            session_id: localStorage.getItem("sessionId"),
          },
          headers: {
            "session-id ": localStorage.getItem("sessionId"),
          },
        }
      )
      .then((response) => {
        this.data = response.data;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
        this.data = error.response.data.detail;
      });

    return { status: this.status, data: this.data };
  }
}
