export const DesignedButton = ({
  text,
  callback,
  className = "designedButton",
}) => {
  return (
    <button className={className} onClick={callback}>
      {text}
    </button>
  );
};
