import axios from "axios";

export class LoginAdminApi {
  static data;
  static status;
  static id;

  static async logIn(email, password) {
    let data = JSON.stringify({ email: email, password: password });

    await axios
      .post("https://forone.2ng.ru:4445/login/", {
        email: email,
        password: password,
      })
      .then((response) => {
        this.id = response.data.session_id;
        this.status = response.status;
      });

    return { status: this.status, id: this.id, data: this.data };
  }
}
