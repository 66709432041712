import { useState } from "react";
import { LoginApi } from "../Api/LoginApi";
import { DesignedButton } from "../Components/UI/DesignedButton";
import { DesignedInput } from "../Components/UI/DesignedInput";
import { DesignedPassword } from "../Components/UI/DesignedPassword";
import { useNavigate } from "react-router-dom";
import { TeamAccountApi } from "../Api/TeamAccountApi";
import ReactLoading from "react-loading";

export const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);

  function onEmailChange(e) {
    setEmail(e);
  }

  function onPasswordChange(e) {
    setPassword(e);
  }

  async function fetch() {
    const loginFetch = await LoginApi.logIn({ email, password });

    setLoad(true);
    if (loginFetch.status === 200) {
      localStorage.setItem("sessionId", loginFetch.id);

      const teamFetch = await TeamAccountApi.getTeam();

      if (teamFetch.length > 0) {
        navigate("/team");
      } else if (teamFetch.length === 0) {
        navigate("/application");
      }
    } else if (loginFetch.status === 401) {
      alert("Почта или пароль введены неверно");
      setLoad(false);
      return;
    } else {
      setLoad(false);
    }
  }

  function onLogin(e) {
    e.preventDefault();
    fetch();
  }

  return (
    <div className="registrationPage">
      <form className="registrationForm">
        <DesignedInput
          type={"email"}
          placeholder={"Введите почту"}
          onChange={onEmailChange}
          required={true}
        />
        <DesignedPassword
          type={"password"}
          placeholder={"Введите пароль"}
          onChange={onPasswordChange}
          required={true}
        />
        {load ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <DesignedButton text={"Войти"} callback={onLogin} />
        )}
        <div className="switchSign" onClick={() => navigate("/registration")}>
          Зарегистрироваться
        </div>
        <div className="switchSign" onClick={() => navigate("/recover")}>
          Забыли пароль?
        </div>
      </form>
    </div>
  );
};
