import { useEffect, useState } from "react";

const TimeDisplay = ({ teamId, date, checkDate }) => {
  const [timeDiff, setTimeDiff] = useState("");
  const [dateStatus, setDateStatus] = useState(false);

  useEffect(() => {
    const updateTimeDifference = () => {
      const dateOffset = new Date().getTimezoneOffset();

      const diffMs = Math.abs(new Date() - new Date(date));

      let hours =
        dateOffset >= 0
          ? Math.floor(diffMs / (3600 * 1000) - dateOffset / 60)
          : Math.floor(diffMs / (3600 * 1000) + dateOffset / 60);

      const minutes = Math.floor((diffMs % (3600 * 1000)) / (60 * 1000));
      const seconds = Math.floor((diffMs % (60 * 1000)) / 1000);

      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      setTimeDiff(`${hours}:${formattedMinutes}:${formattedSeconds}`);
    };

    const intervalId = setInterval(updateTimeDifference, 1000);

    updateTimeDifference();

    return () => clearInterval(intervalId);
  }, [date]);

  useEffect(() => {
    if (Number(timeDiff.split(":")[0]) >= 24) {
      setDateStatus(false);
    } else {
      setDateStatus(true);
    }
  }, [timeDiff]);

  useEffect(() => {
    checkDate({ check_date: dateStatus, team_id: teamId });
  }, [dateStatus]);

  return (
    <>
      {Number(timeDiff.split(":")[0]) >= 24 ? (
        <div className="boldRow">24:00:00</div>
      ) : (
        <div className="boldRow">{timeDiff}</div>
      )}
    </>
  );
};

export default TimeDisplay;
