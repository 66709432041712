import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.PNG";

const Header = () => {
  const navigate = useNavigate();

  function exit() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className="header">
      <div className="headerBox">
        <div className="headerContent">
          <a href="https://заодно.team">
            <img alt="" src={logo} className="headLogoImage"></img>
          </a>
          <a href="https://xn--80aih0adc.team/#direction">Направления</a>
          <a href="https://xn--80aih0adc.team/#partners">Наши партнеры</a>
          <a href="https://xn--80aih0adc.team/#about">О нас</a>
          <a href="https://xn--80aih0adc.team/#waiting">Кого мы ждем</a>
          <a href="https://xn--80aih0adc.team/#sponsor">Стать спонсором</a>
          <a href="https://xn--80aih0adc.team/#contacts">Конакты</a>

          {localStorage.getItem("sessionId") ? (
            <div onClick={exit}>Выход </div>
          ) : (
            <a href="https://xn--80aih0adc.team/#popup:choice">Регистрация</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
