import { useEffect, useState } from "react";
import { ApplicationCheckboxInput } from "../../UI/Application/ApplicationInputs/ApplicationCheckboxInput";
import { ApplicationFileInput } from "../../UI/Application/ApplicationInputs/ApplicationFileInput";
import { ApplicationSelectInput } from "../../UI/Application/ApplicationInputs/ApplicationSelectInput";
import { ApplicationTextInput } from "../../UI/Application/ApplicationInputs/ApplicationTextInput";
import { ApplicationPhoneInput } from "../../UI/Application/ApplicationInputs/ApplicationPhoneInput";

export const Participant = ({
  solo,
  fillStatus,
  participant,
  secondNameInput,
  firstNameInput,
  thirdNameInput,
  dateInput,
  studyPlaceInput,
  workPlaceInput,
  facultyInput,
  jobTitleInput,
  homeInput,
  phoneNumberInput,
  emailInput,
  groupInput,
  disabilityInput,
  genderInput,
  photoInput,
  medCertificate,
}) => {
  const [disabledGroup, setDisabledGroup] = useState(true);
  const [studyStatus, setStudyStatus] = useState(false);
  const [workStatus, setWorkStatus] = useState(false);
  const genderList = ["Мужчина", "Женщина"];

  function handleSecondName(e) {
    secondNameInput(e);
  }

  function handleFirstName(e) {
    firstNameInput(e);
  }

  function handleThirdName(e) {
    thirdNameInput(e);
  }

  function handleDate(e) {
    dateInput(e);
  }

  function handleStudyPlace(e) {
    studyPlaceInput(e);
  }

  function handleWorkPlace(e) {
    workPlaceInput(e);
  }

  function handleFaculty(e) {
    facultyInput(e);
  }

  function handleJobTitle(e) {
    jobTitleInput(e);
  }

  function handleHome(e) {
    homeInput(e);
  }

  function handlePhoneNumber(e) {
    phoneNumberInput(e);
  }

  function handleEmail(e) {
    emailInput(e);
  }

  function handleGroup(e) {
    groupInput(e);
  }

  function handleDisabity(e) {
    disabilityInput(e);

    if (e !== true) {
      setDisabledGroup(true);
    } else {
      setDisabledGroup(false);
    }
  }

  function handleGender(e) {
    genderInput(e);
  }

  function handlePhoto(e) {
    photoInput(e.files[0]);
  }

  function handleMedCertificate(e) {
    medCertificate(e.files[0]);
  }

  useEffect(() => {
    if (participant.studyPlace) {
      setStudyStatus(true);
    } else {
      setStudyStatus(false);
      facultyInput("");
    }

    if (participant.workPlace) {
      setWorkStatus(true);
    } else {
      setWorkStatus(false);
      jobTitleInput("");
    }

    if (participant.disability) {
      setDisabledGroup(true);
    } else {
      setDisabledGroup(false);
    }
  }, [participant.studyPlace, participant.workPlace, participant.disability]);

  return (
    <form className="participant">
      <div className="participantHead">
        Информация об участнике {participant.number}
      </div>
      <ApplicationTextInput
        value={participant.secondName}
        onChange={handleSecondName}
        text={"Фамилия"}
        placeholder={"Введите фамилию"}
        className={fillStatus && !participant.secondName && "error"}
      />
      <ApplicationTextInput
        value={participant.firstName}
        onChange={handleFirstName}
        text={"Имя"}
        placeholder={"Введите имя"}
        className={fillStatus && !participant.firstName && "error"}
      />
      <ApplicationTextInput
        value={participant.thirdName}
        onChange={handleThirdName}
        text={"Отчество"}
        placeholder={"Введите отчество"}
        className={fillStatus && !participant.thirdName && "error"}
      />
      <ApplicationSelectInput
        value={participant.gender}
        onChange={handleGender}
        text={"Пол"}
        optionList={genderList}
        className={fillStatus && !participant.gender && "error"}
      />
      <ApplicationTextInput
        value={participant.date}
        onChange={handleDate}
        text={"Дата рождения"}
        type="date"
        className={fillStatus && !participant.date && "error"}
      />
      <ApplicationFileInput onChange={handlePhoto} text={"Загрузка фото"} />
      <ApplicationTextInput
        value={participant.studyPlace}
        onChange={handleStudyPlace}
        text={"Место учёбы"}
        placeholder={"Введите место учёбы"}
        className={
          fillStatus &&
          !participant.studyPlace &&
          !participant.workPlace &&
          "error"
        }
      />{" "}
      <ApplicationTextInput
        value={participant.workPlace}
        onChange={handleWorkPlace}
        text={"Место работы"}
        placeholder={"Введите место работы"}
        className={
          fillStatus &&
          !participant.studyPlace &&
          !participant.workPlace &&
          "error"
        }
      />
      {studyStatus && (
        <ApplicationTextInput
          value={participant.faculty}
          onChange={handleFaculty}
          text={"Университет, факультет, курс, группа"}
          placeholder={"Введите университет, факультет, курс, группа"}
          className={fillStatus && !participant.faculty && "error"}
        />
      )}
      {workStatus && (
        <ApplicationTextInput
          value={participant.jobTitle}
          onChange={handleJobTitle}
          text={"Должность"}
          placeholder={"Введите должность"}
          className={fillStatus && !participant.jobTitle && "error"}
        />
      )}
      <ApplicationTextInput
        value={participant.homeAddress}
        onChange={handleHome}
        text={"Домашний адрес"}
        placeholder={"Введите домашний адрес"}
        className={fillStatus && !participant.homeAddress && "error"}
      />
      <ApplicationPhoneInput
        value={participant.phoneNumber}
        onChange={handlePhoneNumber}
        text={"Контактный телефон"}
        placeholder={"Введите контактный телефон"}
        className={fillStatus && !participant.phoneNumber && "error"}
        type="phone"
      />
      <ApplicationTextInput
        value={participant.email}
        onChange={handleEmail}
        text={"Email"}
        placeholder={"Введите email"}
        type="email"
        className={fillStatus && !participant.email && "error"}
      />
      <ApplicationCheckboxInput
        value={participant.disability}
        onChange={handleDisabity}
        text={"Наличие инвалидности"}
        className={solo && fillStatus && !participant.email && "error"}
      />
      <ApplicationTextInput
        value={participant.group}
        disabled={!participant.disability}
        onChange={handleGroup}
        text={"Группа инвалидности (при наличии)"}
        placeholder={"Введите группу инвалидности"}
      />
      <ApplicationFileInput
        value={participant?.medCertificateClear}
        disabled={!participant.disability}
        onChange={handleMedCertificate}
        text={
          "Загрузка скана-копии справки медико-социальной экспертизы, подтверждающей статус инвалида"
        }
        className={
          solo && fillStatus && !participant.medCertificate && "errorMed"
        }
      />
    </form>
  );
};
