import axios from "axios";

export class AdminApi {
  static sendData;
  static teamData;
  static status;
  static ratings;

  static async getTeam() {
    await axios
      .post("https://forone.2ng.ru:4445/get_team/", {
        session_id: localStorage.getItem("sessionId"),
      })
      .then((response) => {
        this.teamData = response.data;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
      });

    return { data: this.teamData, status: this.status };
  }

  static async sendScore(data) {
    await axios
      .post("https://forone.2ng.ru:4445/rate_team/", {
        request: data,
        session_request: {
          session_id: localStorage.getItem("sessionId"),
        },
      })
      .then((response) => {
        this.sendData = response.data;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
      });

    return { data: this.sendData, status: this.status };
  }

  static async getRatings() {
    await axios
      .get("https://forone.2ng.ru:4445/ratings/", {
        params: {
          session_id: localStorage.getItem("sessionId"),
        },
      })
      .then((response) => {
        this.ratings = response.data;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
      });

    return { ratings: this.ratings, status: this.status };
  }

  static async getTeamRating(id) {
    await axios
      .get(`https://forone.2ng.ru:4445/team_info/${id}`, {
        params: {
          team_id: id,
          session_id: localStorage.getItem("sessionId"),
        },
      })
      .then((response) => {
        this.teamData = response.data;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
      });

    return { data: this.teamData, status: this.status };
  }
}
