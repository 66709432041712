
export const ApplicationFileInput = ({
  text,
  className = "",
  onChange,
  disabled,
}) => {


  return (
    <div className={`applicationInput ${className}`}>
      <div> {text}</div>
      <input
        disabled={disabled}
        type="file"
        onChange={(e) => onChange(e.target)}
      />
    </div>
  );
};
