export const DesignedInput = ({
  placeholder,
  type,
  required,
  onChange,
  multiple,
  disabled,
}) => {
  function onTextInput(e) {
    type === "file" ? onChange(e.target) : onChange(e.target.value);
  }

  return (
    <input
      type={type}
      className="designedInput"
      placeholder={placeholder}
      onChange={onTextInput}
      required={required}
      multiple={multiple}
      disabled={disabled}
    />
  );
};
