import { useEffect, useState } from "react";
import { DesignedButton } from "../Components/UI/DesignedButton";
import emptyTeam from "../Assets/Images/empty_team.png";
import { AdminApi } from "../Api/AdminApi";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

export const ScoredTeamPage = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [scoreList, setScoreList] = useState(["", "", "", "", "", ""]);
  const [teamInfo, setTeamInfo] = useState({
    name: "",
    photo: "",
  });

  const [checkDisability, setCheckDisability] = useState(false);

  function fetchAdminTeam() {
    setLoad(true);
    const team = JSON.parse(localStorage.getItem("scored_team")) || {};

    const checkDisability = team.members.some((item) => item !== undefined);

    setCheckDisability(checkDisability);

    setTeamInfo(team);

    setLoad(false);
  }

  async function sendScore() {
    setLoad(true);
    const fetch = await AdminApi.sendScore({
      team_id: teamInfo.team_id,
      performance_skill: scoreList[0],
      creativity: scoreList[1],
      costume_matching: scoreList[2],
      artistry: scoreList[3],
      inclusivity: scoreList[4],
      mastery_of_combination: scoreList[5],
    });

    if (fetch.status === 200) {
      alert("Заявка оценена");
      window.scrollTo(0, 0);
      navigate("/admin");
    } else {
      navigate("/login_admin");
      setLoad(false);
      return;
    }
    setLoad(false);
  }

  useEffect(() => {
    fetchAdminTeam();
  }, []);

  function onScoreChange(e, index, maxScore) {
    for (let i = 0; i < scoreList.length; i++) {
      if (i === index) {
        if (e.target.value <= maxScore && e.target.value.length <= 2) {
          let list = scoreList;

          let score = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*?)\..*/g, "$1");

          list[index] = score;
          setScoreList([...list]);
        }
      }
    }
  }

  async function onScoreSend() {
    let check = scoreList.includes("");

    if (check) {
      alert("Заполните все поля");
    } else {
      setScoreList(["", "", "", "", "", ""]);
      await sendScore();
      await fetchAdminTeam();
    }
  }

  return (
    <div className="registrationPage">
      <div className="teamCard">
        <div className="teamIcon">
          {load ? (
            <ReactLoading
              type="spinningBubbles"
              color="black"
              height={"50px"}
              width={"50px"}
            />
          ) : teamInfo.photo ? (
            <img src={teamInfo.photo} alt="Фото команды"></img>
          ) : (
            <img
              src={emptyTeam}
              alt="Фото команды"
              className="emptyTeamPhoto"
            ></img>
          )}
        </div>
        <div className="teamCardContent">
          <div className="teamName">{teamInfo.name}</div>
          <div className="teamURL">
            <div onClick={() => window.open(teamInfo.video)}>ВИДЕО</div>
          </div>
          <div style={{ textAlign: "center" }}>
            Участник с инвалидностью:{" "}
            {checkDisability ? "присутствует" : "отсутствует"}
          </div>
        </div>
      </div>
      {/* <div className="adminButtonBox">
        <DesignedButton text={"ДРУГАЯ КОМАНДА"} />
      </div> */}
      <div className="designedTable adminTeamTable">
        <div className="tableHead">
          <div>Критерии</div>
          <div>Баллы</div>
        </div>
        <div className="tableBody">
          <div className="tableRow">
            <div>Исполнительское мастерство выбранной композиции/песни</div>
            <div>
              <input
                value={scoreList[0]}
                onChange={(e) => onScoreChange(e, 0, 30)}
                placeholder="Максимально 30"
                className="tableRowInput"
              />
            </div>
          </div>{" "}
          <div className="tableRow">
            <div>Креативность, оригинальность (творческий подход)</div>
            <div>
              <input
                value={scoreList[1]}
                onChange={(e) => onScoreChange(e, 1, 20)}
                placeholder="Максимально 20"
                className="tableRowInput"
              />
            </div>
          </div>{" "}
          <div className="tableRow">
            <div>Имидж и соответствие костюма выбранному образу</div>
            <div>
              <input
                value={scoreList[2]}
                onChange={(e) => onScoreChange(e, 2, 10)}
                placeholder="Максимально 10"
                className="tableRowInput"
              />
            </div>
          </div>{" "}
          <div className="tableRow">
            <div>
              Артистизм, уверенность и харизма - способность покорить зрителя
            </div>
            <div>
              <input
                value={scoreList[3]}
                onChange={(e) => onScoreChange(e, 3, 20)}
                placeholder="Максимально 20"
                className="tableRowInput"
              />
            </div>
          </div>{" "}
          <div className="tableRow">
            <div>
              Инклюзивность - совместное творчество людей с ограниченными
              возможностями здоровья и без в случае инклюзивного коллектива и
              проведенная работа по развитию человека с инвалидностью в случае
              соло участника с инвалидностью
            </div>
            <div>
              <input
                value={scoreList[4]}
                onChange={(e) => onScoreChange(e, 4, 10)}
                placeholder="Максимально 10"
                className="tableRowInput"
              />
            </div>
          </div>{" "}
          <div className="tableRow">
            <div>
              Мастерство сочетания возможностей участников с инвалидностью и без
              инвалидности, степень сложности ограничений у исполнителей с
              инвалидностью
            </div>
            <div>
              <input
                value={scoreList[5]}
                onChange={(e) => onScoreChange(e, 5, 10)}
                placeholder="Максимально 10"
                className="tableRowInput"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="adminButtonBox">
        {load ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <DesignedButton text={"Подтвердить"} callback={onScoreSend} />
        )}
        <DesignedButton
          text={"Список оцененных команд"}
          callback={() => {
            window.scrollTo(0, 0);
            navigate("/admin");
          }}
        />
      </div>
    </div>
  );
};
