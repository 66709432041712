import { useEffect, useState } from "react";
import { DesignedButton } from "../Components/UI/DesignedButton";
import { DesignedInput } from "../Components/UI/DesignedInput";
import { DesignedPassword } from "../Components/UI/DesignedPassword";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { LoginAdminApi } from "../Api/LoginAdminApi";

export const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);

  function onEmailChange(e) {
    setEmail(e);
  }

  function onPasswordChange(e) {
    setPassword(e);
  }

  async function fetch() {
    const loginFetch = await LoginAdminApi.logIn(email, password);

    setLoad(true);
    if (loginFetch.status === 200) {
      localStorage.setItem("sessionId", loginFetch.id);

      window.scrollTo(0, 0);
      navigate("/score");
    } else if (loginFetch.status === 401) {
      alert("Почта или пароль введены неверно");
      setLoad(false);
      return;
    } else {
      setLoad(false);
    }
  }

  function onLogin(e) {
    e.preventDefault();
    fetch();
  }

  return (
    <div className="registrationPage">
      <form className="registrationForm">
        <DesignedInput
          type={"email"}
          placeholder={"Введите почту"}
          onChange={onEmailChange}
          required={true}
        />
        <DesignedPassword
          type={"password"}
          placeholder={"Введите пароль"}
          onChange={onPasswordChange}
          required={true}
        />
        {load ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <DesignedButton text={"Войти"} callback={onLogin} />
        )}
      </form>
    </div>
  );
};
